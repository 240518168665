import { REGISTER_ICCID, VERIFY_CUSTOMER, INITIATE_IDENTITY, VERIFY_VALID_SIM, VALID_SIM } from "redux/constants";

const initialState = { 
  customerData:[],
  verifyCustomerData:[],
  identityData:[],
  validSimStatus:{},
  validSim:''

}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case REGISTER_ICCID: {
      return {
        ...state,
        customerData: payload,
      }
    }

    case INITIATE_IDENTITY: {
      return {
        ...state,
        identityData: payload,
      }
    }
    case VERIFY_CUSTOMER: {
      return {
        ...state,
        verifyCustomerData: payload,
      }
    }
    case VERIFY_VALID_SIM: {
      return {
        ...state,
        validSimStatus: payload,
      }
    }

    case VALID_SIM: {
      return {
        ...state,
        validSim: payload,
      }
    }

    default:
      return state;
  }
}

export default reducer;