import { combineReducers } from 'redux';
import settings from './settings/reducer';
import user from './user/reducer';
import account from './account/reducer';
import sim from './sim/reducer';
import cartDetails from './cart/reducer';
import common from './common/reducer';
import customerSupport from './CustomerSupport/reducer';
import dashboard from './dashboard/reducer';
import portInRequest from './PortIn/reducer';
import customerDetails from './SimVerification/reducer'

const reducers = combineReducers({
    settings,
    account,
    user,
    sim,
    cartDetails,
    common,
    customerSupport,
    dashboard,
    portInRequest,
    customerDetails
});

export default reducers;